import * as React from "react";
import * as style from "./styles/legal.module.scss";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection/";
import BoundedSection from "components/BoundedSection";

const LegalPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("cookiesPolicy.seo.title")}
      seoDescription={t("cookiesPolicy.seo.description")}
    >
      <HeaderSection
        title={t("cookiesPolicy.header.title")}
        description={<Trans i18nKey="cookiesPolicy.header.description" />}
      />
      <BoundedSection className={style.LegalInfo}>
        <p>
          En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley
          34/2002, de 11 de julio, de Servicios de la Sociedad de la Información
          y de Comercio Electrónico, este Sitio Web le informa, en esta sección,
          sobre la política de recogida y tratamiento de cookies.
        </p>
        <h4>¿Qué son las Cookies?</h4>
        <p>
          Las cookies son pequeños ficheros de texto que algunos servidores
          guardan en nuestro disco duro con nuestro consentimiento, y que
          contiene información acerca de lo que hemos estado haciendo en sus
          páginas (tiempo de permanencia en una determinada pantalla, obtención
          de información específica acerca de un determinado producto, etc.…) La
          gran mayoría de las cookies tiene fecha de caducidad, que puede
          oscilar desde el tiempo que dure la sesión hasta una fecha futura
          especificada, a partir de la cual dejan de ser operativa.
        </p>
        <h3>
          <span>01.</span>Las cookies que utiliza este Sitio Web
        </h3>
        <p>
          Las cookies empleadas en este Sitio Web se asocian únicamente con un
          usuario anónimo y su ordenador, no proporcionan referencias que
          permitan deducir el nombre y apellidos del usuario y no pueden leer
          datos de su disco duro ni incluir virus en sus textos. Nuestro Sitio
          Web tampoco puede leer las cookies implantadas en el disco duro del
          Usuario desde otros servidores.
        </p>
        <p>
          El usuario puede libremente decidir acerca de la implantación o no en
          su disco duro de las cookies empleadas en nuestro Sitio Web. En este
          sentido, el usuario puede configurar su navegador para aceptar o
          rechazar por defecto todas las cookies o para recibir un aviso en
          pantalla de la recepción de cada cookie y decidir en ese momento su
          implantación o no en su disco duro. Para esto, recomendamos consultar
          la sección de ayuda de su navegador para saber cómo cambiar la
          configuración que actualmente emplea.
        </p>
        <p>
          Como usuario, usted tiene el derecho de rechazar todas las cookies o
          permitir que funcionen solamente las que considere necesarias, y podrá
          navegar normalmente por nuestro Sitio Web, con el único inconveniente
          de no poder disfrutar de ciertas funcionalidades que requieran la
          instalación de alguna cookie en su servidor. En cualquier caso, el
          Usuario podrá eliminar las cookies implantadas en su disco duro en
          cualquier momento, siguiendo el procedimiento establecido en la
          sección de ayuda de su navegador y que posteriormente detallaremos.
        </p>
        <p>
          Según los términos incluidos en el artículo 22.2 de la Ley 34/2002 de
          Servicios de la Sociedad de la Información y Comercio Electrónico, si
          usted decide continuar navegando con todas las cookies habilitadas,
          estará prestando su consentimiento para el empleo de las cookies que
          detallamos a continuación.
        </p>
        <p>Las cookies de este Sitio Web ayudan a:</p>
        <ul>
          <li>Hacer que este Sitio Web funcione correctamente;</li>
          <li>Permitir visualizar videos u otros recursos multimedia;</li>
          <li>Mejorar la velocidad / seguridad del Sitio Web;</li>
          <li>Poder compartir páginas con sus redes sociales;</li>
          <li>Guardar contraseñas de usuarios para futuras visitas.</li>
        </ul>
        <p>No utilizamos jamás cookies para:</p>
        <ul>
          <li>
            Recoger información de identificación personal (sin su
            consentimiento expreso);
          </li>
          <li>Recoger información sensible (sin su consentimiento expreso);</li>
          <li>Compartir datos de identificación personal a terceros.</li>
        </ul>
        <h3>
          <span>02.</span>Cookies de terceros que utilizamos en este Sitio Web y
          que debe conocer
        </h3>
        <p>
          Este Sitio Web, como la gran mayoría de los Sitios Web, incluye
          funcionalidades proporcionadas por terceros.
        </p>
        <p>
          También se ponen a prueba regularmente nuevos diseños o servicios de
          terceros para recomendaciones e informes.
        </p>
        <p>
          Esto puede modificar ocasionalmente la configuración de cookies y que
          aparezcan cookies no detalladas en la presente política. Es importante
          informarle que se tratan de cookies provisionales que no siempre es
          posible informar y que solo tienen finalidades de estudio y
          valoración. En ningún caso se van a utilizar cookies que comprometan
          su privacidad.
        </p>
        <p>
          A continuación, y según exige el artículo 22.2 de la LSSI se detallan
          las cookies que pueden instalarse habitualmente durante la navegación
          por este Sitio Web:
        </p>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Duración</th>
              <th>Finalidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>2 años</td>
              <td>
                Se usa para distinguir a los usuarios. Es una cookie
                perteneciente a Google Analytics.
              </td>
            </tr>
            <tr>
              <td>_gid </td>
              <td>24 horas</td>
              <td>
                Se usa para distinguir a los usuarios. Es una cookie
                perteneciente a Google Analytics.
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>1 minuto</td>
              <td>
                Se usa para limitar el porcentaje de solicitudes. Es una cookie
                perteneciente a Google Analytics.
              </td>
            </tr>
            <tr>
              <td>__utma</td>
              <td>Dos años a partir de la configuración o actualización</td>
              <td>
                Se usa para distinguir usuarios y sesiones. La cookie se crea
                cuando se ejecuta la biblioteca JavaScript y no hay ninguna
                cookie __utma. La cookie se actualiza cada vez que se envían
                datos a Google Analytics.
              </td>
            </tr>
            <tr>
              <td>__utmz</td>
              <td>Seis meses a partir de la configuración o actualización </td>
              <td>
                Almacena la fuente de tráfico o la campaña que explica cómo ha
                llegado el usuario al sitio. La cookie se crea cuando se ejecuta
                la biblioteca JavaScript y se actualiza cada vez que se envían
                datos a Google Analytics.
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
          <span>03.</span>¿Cómo puedo gestionar y desactivar estas cookies?
        </h3>
        <p>
          En el caso de no desear que el Sitio Web no instale ninguna cookie en
          su equipo, cabe la posibilidad de adaptar su navegador de modo que se
          te notifique antes de que se descargue ninguna cookie.
        </p>
        <p>
          Asimismo, se puede igualmente modificar la configuración del navegador
          de forma que rechace todas las cookies o únicamente las cookies de
          terceros. También es posible eliminar cualquiera de las cookies que ya
          se encuentren en su equipo; para ello usted tendrá que adaptar por
          separado la configuración de cada navegador y equipo que utilice.
        </p>
        <p>
          Ponemos a disposición de los usuarios que quieran impedir la
          instalación de las mencionadas cookies, enlaces facilitados al efecto
          por navegadores cuyo uso se considera más extendido:
        </p>
        <h4>Administración de cookies por navegador:</h4>
        <p>
          <strong>Google Chrome:</strong>{" "}
          http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
          <br />
          <strong>Explorer:</strong>{" "}
          https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
          <br />
          <strong>Firefox:</strong>{" "}
          https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros
          <br />
          <strong>Safari:</strong> http://support.apple.com/kb/ph5042
        </p>
        <p>
          Muchos navegadores permiten activar un modo privado mediante el cual
          las cookies se borran siempre después de su visita y, dependiendo de
          cada navegador, este modo privado puede tener diferentes nombres.
          Abajo puede encontrar una lista de los navegadores más comunes y los
          diferentes nombres de este “modo privado”:
        </p>
        <ul>
          <li>Internet Explorer 8 y superior: InPrivate</li>
          <li>FireFox 3.5 y superior: Navegación Privada</li>
          <li>Google Chrome 10 y superior: Incógnito</li>
          <li>Safari 2 y superior: Navegación Privada</li>
          <li>Opera 10.5 y superior: Navegación Privada</li>
        </ul>
        <p>
          Por favor, lee atentamente la sección de ayuda de su navegador para
          conocer más acerca de cómo activar el “modo privado”. Podrá seguir
          visitando nuestro Sitio Web aunque su navegador esté en “modo
          privado”, no obstante, la experiencia de usuario puede no ser óptima y
          algunas utilidades pueden no funcionar.
        </p>
        <p>
          Si necesita más información acerca del bloqueo en su equipo de
          cookies, no dude en atender la explicación que se le da en este enlace
          Web: http://windows.microsoft.com/es-es/windows-vista/block-or-
          allow-cookies
        </p>
        <h4>Cambios en la política de cookies</h4>
        <p>
          Es posible que actualicemos la Política de Cookies de nuestro Sitio
          Web. Por ello, le recomendamos revisar esta política cada vez que
          acceda a nuestro Sitio Web con el objetivo de estar adecuadamente
          informado sobre cómo y para qué usamos las cookies.
        </p>
        <h4>Política de privacidad</h4>
        <p>
          Puede leer nuestra política de privacidad para una mayor información
          sobre el tratamiento de datos personales.
        </p>
        <h4>Contacto</h4>
        <p>
          Si tuviera alguna duda, comentario o sugerencia sobre la Política de
          Cookies, por favor escriba a: info@trileucosolutions.com
        </p>
      </BoundedSection>
    </PageWrapper>
  );
};

export default LegalPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
